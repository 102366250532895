import React, { useState } from "react";
import {useEffect} from "react";
import telugu from "./bibleJsons/telugu.json";
import english from "./bibleJsons/english.json";
import CopyClipboard from './images/CopyClipboard.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {Col} from "react-bootstrap";
import {htmlToText} from 'html-to-text';

import englishPosts from './postsJsons/englishPosts.json';
import teluguPosts from './postsJsons/teluguPosts.json';

function Article() {
  const [languageName, setLanguageName] = useState("Telugu");
  const [posts, setPosts] = useState(teluguPosts);
  const [post, setPost] = useState(posts.posts[0]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [books, setBooks] = useState(telugu.books);
  const [selectedVerse, setSelectedVerse] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [fontSize, setFontSize] = useState(17);

  const languageMap = {
    "English": english,
    "Telugu": telugu
  };

  const mapBible = (bible) => {
    const bibleMap = {};
    if (bible !== undefined) {
      bible.books.forEach((book) => {
        bibleMap[book.bnumber] = {};
        book.chapters.forEach((chapter) => {
          bibleMap[book.bnumber][chapter.cnumber] = {};
          chapter.verses.forEach((verse) => {
            bibleMap[book.bnumber][chapter.cnumber][verse.vnumber] = verse.text;
          });
        });
      });
    }
    return bibleMap;
  };

  const changeLanguage = (event) => {
      const selectedLanguage = event.target.value;
      setLanguageName(selectedLanguage);
      if (selectedLanguage === "English") {
        setPosts(englishPosts);
        setPost(englishPosts.posts[0]);
        setBooks(english.books);
      } else {
        setPosts(teluguPosts);
        setPost(teluguPosts.posts[0]);
        setBooks(telugu.books);
      }
  };

  const changePosts = (event) => {
    const selectedPost = event.target.value;
    const selectedPostData = posts.posts.find((post) => post.title === selectedPost);
    setPost(selectedPostData);
  }

  const handleSearch = () => {
      const results = [];
      if (searchQuery) {
        posts.posts.forEach((post) => {
        if (post.title.toLowerCase().includes(searchQuery.toLowerCase()) || post.content.toLowerCase().includes(searchQuery.toLowerCase())) {
          results.push({
            id: post.id,
            title: post.title,
            content: post.content
          });
          }
        });
      }
      setSearchResult(results);
    };

  const navigateTo = (result) => {
    setPost(posts.posts[result.id - 1]);
  };

  const highlightText = (text, query) => {
    if (!query) return text;
      const parts = text.split(new RegExp(`(${query})`, 'gi'));
      return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <span key={index} className="btn bg-warning bg-opacity-10 fw-medium text-warning py-2 px-4 search_heilight">{part}</span> : part
      );
    };

  const getVerse = (bibleMap, bookName, chapterNumber, verseNumber) => {
    if (bibleMap[bookName] && bibleMap[bookName][chapterNumber] && bibleMap[bookName][chapterNumber][verseNumber]) {
      return bibleMap[bookName][chapterNumber][verseNumber];
    }
    return '';
  };

  const selectedBibleMap = mapBible(languageMap[languageName]);
    const findBookNumberByBookName = (bname) => {
      const book = books.find((book) => book.bname === bname.trim());
      return book ? book.bnumber : '';
    }

  const getVerseDetails = (bookName, chapterNumber, verseNumber) => {
      let text = "";
      const bookNumber = findBookNumberByBookName(bookName);
      const verseSeparatedByComa = verseNumber.split(",");
        for (let j = 0; j < verseSeparatedByComa.length; j++) {
          const verseText = verseSeparatedByComa[j].split("-");
            if (verseText.length > 1) {
                const startVerse = parseInt(verseText[0]);
                const endVerse = parseInt(verseText[1]);
                for (let i = startVerse; i <= endVerse; i++) {
                    const verse = getVerse(selectedBibleMap, bookNumber, chapterNumber, i);
                    if (verse !== undefined && verse !== null && verse !== '') {
                      text = text + "<br/>" + i + " " + verse + " ";
                    }
                }
            } else {
              const verse = getVerse(selectedBibleMap, bookNumber, chapterNumber, verseText[0]);
              if (verseSeparatedByComa.length > 1 || verseText.length > 1) {
                text = text + "<br/>" + verseText[0] + " " + verse + " ";
              } else {
                text = text + verse;
              }
            }
        }

      return text;
  }

    const renderVerseText = (content) => {
      let text1 = "";
      const references = content.split(";");
      const text = references.map((ref) => {
        const referencePart = ref.trim().split(" ");
          if (referencePart.length === 3) {
            const string1 = ref.trim().split(" ");
            const [refChapter, refVerse] = string1[2].split(":");
            const refBook = string1[0] + " " + string1[1];
            const verseText = "<b>" + refBook + " " + refChapter + ":" + refVerse + "</b> " + getVerseDetails(refBook.trim(), refChapter.trim(), refVerse.trim());
            if (verseText) {
              if (text1) {
                text1 = text1 + "<br/><br/>" + verseText.replace(/'/g, `&apos;`);
              } else {
                text1 = verseText.replace(/'/g, `&apos;`);
              }
            }
          } else {
            const [refBook, referenceVerse] = ref.trim().split(" ");
            const [refChapter, refVerse] = referenceVerse.split(":");
            const verseText = "<b>" + refBook + " " + refChapter + ":" + refVerse + "</b> " + getVerseDetails(refBook.trim(), refChapter.trim(), refVerse.trim());
            if (verseText) {
              if (text1) {
                text1 = text1 + "<br/><br/>" + verseText.replace(/'/g, `&apos;`);
              } else {
                text1 = verseText.replace(/'/g, `&apos;`);
              }
            }
          }
      });
      return text1;
    };

  // Event Listener for Popup
    document.addEventListener("click", function (event) {
      const trigger = event.target.closest(".popup-trigger");
      if (trigger) {
        const content = trigger.getAttribute("data-content");
        const reference = trigger.getAttribute("data-reference");
        const type = trigger.getAttribute("data-type");

        if (content) {
          showPopup(content, type);
        } else if (reference) {
          showPopup(JSON.parse(reference), "References");
        }
      }
    });

    // Function to Show Popup
    function showPopup(content, type) {
      let popup = document.getElementById("popup");
      if (!popup) {
        popup = document.createElement("div");
        popup.id = "popup";
        popup.innerHTML = `<span id="close-popup">&times;</span>
                             <div class="popup-content">
                                 <h3 id="popup-heading"></h3>
                                 <div id="popup-text"></div>
                             </div>`;
        document.body.appendChild(popup);
      }

      // Set heading based on type
      document.getElementById("popup-heading").textContent = type ? type.charAt(0).toUpperCase() + type.slice(1) : "Details";
      document.getElementById("popup-text").innerHTML = renderVerseText(content);

      popup.style.display = "block";

      document.getElementById("close-popup").addEventListener("click", function () {
        popup.style.display = "none";
      });
    }
  return (
    <div>
      <Container className="position-relative" style={{ fontSize: `${fontSize}px` }}>
        <Row className="top_search_bar">
          <Col className="col-lg-8 col-md-12 col-sm-12">
            <div className="left_search_bar">
              <Row>
                <Col className="col-auto">
                  <div className="form-group position-relative">
                    <select className="form-select form-control h-55 bg-body-bg border-0 text-dark rounded-pill" value={languageName} onChange={changeLanguage}>
                      {Object.keys(languageMap).map((language, index) => (
                        <option key={index}>{language}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col className="col-auto">
                  <div className="form-group position-relative">
                    <select className="form-select form-control h-55 bg-body-bg border-0 text-dark rounded-pill" value={post.title} onChange={changePosts}>
                      {posts.posts.map((post) => (
                        <option key={post.id}>{post.title}</option>
                      ))}
                    </select>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="col-lg-4 col-md-12 col-sm-12">
            <div className="right_search_bar">
              <div className="form-group position-relative w-100">
                <input className="form-control h-55 bg-body-bg border-0 text-dark rounded-pill" onChange={(e) => setSearchQuery(e.target.value)} placeholder='Select English Lang to search...' type='text' value={searchQuery} />
                <button className="position-absolute top-50 end-0 translate-middle-y bg-primary p-0 border-0 text-center text-white rounded-pill px-3 py-2 me-3 fw-semibold" onClick={handleSearch}>Search</button>
              </div>
            </div>
          </Col>
        </Row>

        {searchResult.length > 0 && (
          <Row className="table_search_content">
            <Col className="col-lg-12 col-md-12 col-sm-12">
              <div className="card bg-white border-0 rounded-3 mb-4">
                <div className="card-body p-4">
                  <h4 className="mb-4">{`Search Result (${searchResult.length})`}</h4>
                  <table>
                    <tbody>
                      {searchResult.map((result, index) => (
                        <tr key={index} className="border-bottom pb-4 mb-4 d-flex justify-content-between">
                          <td className="d-flex verse-container">
                            <div className={`default_language ? 'half-width' : 'full-width'} d-flex`}>
                              <span>{index + 1}.</span> <span><button className="btn bg-danger bg-opacity-10 fw-medium text-danger py-2 px-4 border-0" onClick={() => navigateTo(result)}>
                              {result.id} {result.title}
                            </button></span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row className="lesson_with_buttons">
          <Col className="col-lg-4 col-md-4 col-sm-12 res-2">
            <div className="prev_btn">
              <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={post.id === 1} onClick={() => setPost(posts.posts[posts.posts.indexOf(post) - 1] || post)}>Previous Post</button>
            </div>
          </Col>
          <Col className="col-lg-4 col-md-4 col-sm-12 res-1">
            <div className="lesson_name">
            </div>
          </Col>
          <Col className="col-lg-4 col-md-4 col-sm-12 res-3">
            <div className="next_btn">
              <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={post.id === posts.posts.length} onClick={() => setPost(posts.posts[posts.posts.indexOf(post) + 1] || post)}>Next Post</button>
            </div>
          </Col>
        </Row>

        <Row className="table_content">
          <Col className="col-lg-12 col-md-12 col-sm-12">
            <div className="card bg-white border-0 rounded-3 mb-4">
              <div className="card-body p-4">
                <table>
                  <thead>
                    <tr key={0} className="pb-4 mb-4 d-flex justify-content-end">
                      <td></td>
                      <td>
                        <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" onClick={() => navigator.clipboard.writeText(post.content.replace(/<\/?verse>/g, ''))}>
                          <img width="10" height="10" src={CopyClipboard} alt="logo" /> Copy
                        </button>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-bottom pb-4 mb-4 d-flex justify-content-between">
                      <td className="d-flex verse-container">
                        <div className={'full-width d-flex'}>
                        <span>
                         {post.content.split('\n').map((paragraph, index) => (
                           <p key={index}>
                             {paragraph.startsWith('⁉️') ? (
                               <b>{paragraph}</b>
                             ) : (
                               paragraph.split(/(<verse>.*?<\/verse>)/g).map((part, i) =>
                                 part.startsWith('<verse>') ? (
                                   <a key={i} className="popup-trigger" data-type="reference" data-content={part.replace(/<\/?verse>/g, '')}>
                                     {part.replace(/<\/?verse>/g, '')}
                                   </a>
                                 ) : (
                                   part
                                 )
                               )
                             )}
                           </p>
                         ))}
                       </span>
                        </div>
                      </td>
                      <td>
                        <div className="form-group">
                          <div className="form-check">

                          </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>

        <div className="prev_next_btn_bottom">
          <Row>
            <Col className="col-lg-6 col-md-6 col-sm-12">
              <div className="prev_btn">
                <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={post.id === 1} onClick={() => setPost(posts.posts[posts.posts.indexOf(post) - 1] || post)}>Previous Post</button>
              </div>
            </Col>
            <Col className="col-lg-6 col-md-6 col-sm-12">
              <div className="next_btn">
                <button className="btn btn-primary fw-medium text-white py-2 px-4 rounded-pill" disabled={post.id === posts.posts.length} onClick={() => setPost(posts.posts[posts.posts.indexOf(post) + 1] || post)}>Next Post</button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default Article;
